export interface mydata {
  Name: string;
  Katigoria: string;
  XaraktirismosE3: string;
  Typos: number;
}

export interface mydataIndexed {
  Name: string;
  Katigoria: string;
  XaraktirismosE3: string;
  Typos: number;
  SearchIndex: string;
}

const dataRaw: mydata[] = [
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_001 - Πωλήσεις Παγίων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_564 - Πιστωτικές συναλλαγματικές διαφορές",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_565 - Έσοδα συμμετοχών",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_566 - Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_567 - Κέρδη από αναστροφή προβλέψεων και απομειώσεων",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_568 - Κέρδη από επιμέτρηση στην εύλογη αξία",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_001 - Πωλήσεις για λογ/σμο Τρίτων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Ε3_596 - Επιδοτήσεις - Επιχορηγήσεις",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Ε3_597 - Επιδοτήσεις - Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών",
    "Typos": 0
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "1.1 - Τιμολόγιο Πώλησης",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.2 - Τιμολόγιο Πώλησης (Ενδοκοινοτικές Παραδόσεις)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.3 - Τιμολόγιο Πώλησης (Παραδόσεις Τρίτων Χωρών)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_001 - Πωλήσεις για λογ/σμο Τρίτων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "1.4 - Τιμολόγιο Πώλησης (Πώληση για Λογαριασμό Τρίτων)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_001 - Πωλήσεις Παγίων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Τιμολόγιο Εκκαθάρισης)",
    "Katigoria": "2_9 - Έξοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "1.5 - Τιμολόγιο Πώλησης (Εκκαθάριση Πωλήσεων Τρίτων)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "1.6 - Τιμολόγιο Πώλησης (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "Ίδιες κατηγορίες με τα συχετιζόμενα παραστατικά 1.1, 1.2, 1.3, 1.4 και 1.5",
    "XaraktirismosE3": "Ίδιοι τύποι (Ε3) με τα συχετιζόμενα παραστατικά 1.1, 1.2, 1.3, 1.4 και 1.5",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_564 - Πιστωτικές συναλλαγματικές διαφορές",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_565 - Έσοδα συμμετοχών",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_566 - Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_567 - Κέρδη από αναστροφή προβλέψεων και απομειώσεων",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_568 - Κέρδη από επιμέτρηση στην εύλογη αξία",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_005 - Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "2.1 - Τιμολόγιο Παροχής Υπηρεσιών",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "2.2 - Τιμολόγιο Παροχής (Ενδοκοινοτική Παροχή Υπηρεσιών)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "2.3 - Τιμολόγιο Παροχής (Παροχή Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_564 - Πιστωτικές συναλλαγματικές διαφορές",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_565 - Έσοδα συμμετοχών",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_566 - Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_567 - Κέρδη από αναστροφή προβλέψεων και απομειώσεων",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_568 - Κέρδη από επιμέτρηση στην εύλογη αξία",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "2.4 - Τιμολόγιο Παροχής (Συμπληρωματικό Παραστατικό)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_002 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "3.1 - Τίτλος Κτήσης (Μη Υπόχρεος Εκδότης)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_001 - Πωλήσεις Παγίων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_564 - Πιστωτικές συναλλαγματικές διαφορές",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_565 - Έσοδα συμμετοχών",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_566 - Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_567 - Κέρδη από αναστροφή προβλέψεων και απομειώσεων",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_568 - Κέρδη από επιμέτρηση στην εύλογη αξία",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "3.2 - Τίτλος Κτήσης (Άρνηση Έκδοσης από Υπόχρεο Εκδότη)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "5.1 - Πιστωτικό Τιμολόγιο (Συσχετιζόμενο)",
    "Katigoria": "Ίδιες κατηγορίες με τα συχετιζόμενα παραστατικά 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 2.1, 2.2, 2.3, 2.4, 3.1 και 3.2",
    "XaraktirismosE3": "Ίδιοι τύποι (Ε3) με τα συχετιζόμενα παραστατικά 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 2.1, 2.2, 2.3, 2.4, 3.1 και 3.2",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_001 - Πωλήσεις Παγίων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_564 - Πιστωτικές συναλλαγματικές διαφορές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_565 - Έσοδα συμμετοχών",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_566 - Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_567 - Κέρδη από αναστροφή προβλέψεων και απομειώσεων",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_568 - Κέρδη από επιμέτρηση στην εύλογη αξία",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_001 - Πωλήσεις για λογ/σμο Τρίτων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Ε3_596 - Επιδοτήσεις - Επιχορηγήσεις",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Ε3_597 - Επιδοτήσεις - Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών",
    "Typos": 0
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_002 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_9 - Έξοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "5.2 - Πιστωτικό Τιμολόγιο (Μη Συσχετιζόμενο)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "6.1 - Στοιχείο Αυτοπαράδοσης",
    "Katigoria": "1_6 - Αυτοπαραδόσεις",
    "XaraktirismosE3": "Ε3_595 - Έξοδα σε ιδιοπαραγωγή",
    "Typos": 0
  },
  {
    "Name": "6.1 - Στοιχείο Αυτοπαράδοσης",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "6.2 - Στοιχείο Ιδιοχρησιμοποίησης",
    "Katigoria": "1_6 - Αυτοπαραδόσεις",
    "XaraktirismosE3": "Ε3_595 - Έξοδα σε ιδιοπαραγωγή",
    "Typos": 0
  },
  {
    "Name": "6.2 - Στοιχείο Ιδιοχρησιμοποίησης",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_001 - Πωλήσεις Παγίων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "7.1 - Συμβόλαιο (Έσοδο)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "8.1 - Ενοίκια (Έσοδο)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Δεν υφίσται ποσό (αναγραφή μόνο του φόρου). Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "8.2 - Ειδικό Στοιχείο (Απόδειξη Είσπραξης Φόρου Διαμονής)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_002 - Πωλήσεις Παγίων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_002 - Πωλήσεις για λογ/σμο Τρίτων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.1 - Απόδειξη Λιανικής Πώλησης",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.2 - Απόδειξη Παροχής Υπηρεσιών",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_002 - Πωλήσεις Παγίων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_002 - Πωλήσεις για λογ/σμο Τρίτων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.3 - Απλοποιημένο Τιμολόγιο",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_1 - Έσοδα από Πώληση Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_2 - Έσοδα από Πώληση Προϊόντων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_3 - Έσοδα από παροχή Υπηρεσιών",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_002 - Πωλήσεις Παγίων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_4 - Έσοδα από Πώληση Παγίων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_5 - Λοιπά Έσοδα/ Κέρδη",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_002 - Πωλήσεις για λογ/σμο Τρίτων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "11.4 - Πιστωτικό Στοιχείο Λιανικής",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "11.5 - Απόδειξη Λιανικής Πώλησης για Λογαριασμό Τρίτων",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_002 - Πωλήσεις για λογ/σμο Τρίτων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "11.5 - Απόδειξη Λιανικής Πώλησης για Λογαριασμό Τρίτων",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "11.5 - Απόδειξη Λιανικής Πώλησης για Λογαριασμό Τρίτων",
    "Katigoria": "1_7 - Έσοδα για λογαριασμό τρίτων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "11.5 - Απόδειξη Λιανικής Πώλησης για Λογαριασμό Τρίτων",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_002 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.1 - Έξοδα - Αγορές Λιανικών Συναλλαγών",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.2 - Παροχή Λιανικών Συναλλαγών",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "13.3 - Κοινόχρηστα",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.3 - Κοινόχρηστα",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.3 - Κοινόχρηστα",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.3 - Κοινόχρηστα",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.3 - Κοινόχρηστα",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "13.4 - Συνδρομές",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.4 - Συνδρομές",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.4 - Συνδρομές",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.4 - Συνδρομές",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.4 - Συνδρομές",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_588 - Ασυνήθη έξοδα, ζημιές και πρόστιμα",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_002 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "13.31 - Πιστωτικό Στοιχείο Λιανικής (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.1 - Τιμολόγιο (Ενδοκοινοτικές Αποκτήσεις)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.2 - Τιμολόγιο (Αποκτήσεις Τρίτων Χωρών)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.3 - Τιμολόγιο (Ενδοκοινοτική Λήψη Υπηρεσιών)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.4 - Τιμολόγιο (Λήψη Υπηρεσιών Τρίτων Χωρών)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.5 - Ε.Φ.Κ.Α. και λοιποί Ασφαλιστικοί Οργανισμοί",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_007 - Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων",
    "Typos": 1
  },
  {
    "Name": "14.5 - Ε.Φ.Κ.Α. και λοιποί Ασφαλιστικοί Οργανισμοί",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_588 - Ασυνήθη έξοδα, ζημιές και πρόστιμα",
    "Typos": 1
  },
  {
    "Name": "14.5 - Ε.Φ.Κ.Α. και λοιποί Ασφαλιστικοί Οργανισμοί",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.5 - Ε.Φ.Κ.Α. και λοιποί Ασφαλιστικοί Οργανισμοί",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.5 - Ε.Φ.Κ.Α. και λοιποί Ασφαλιστικοί Οργανισμοί",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.30 - Παραστατικά Οντότητας ως αναγράφονται από την ίδια",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_4 - Γενικά Έξοδα με δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_007 - Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "14.31 - Πιστωτικό (Ημεδαπής/Αλλοδαπής)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_002 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_1 - Αγορές Εμπορευμάτων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_2 - Αγορές Α'-Β' Υλών",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_3 - Λήψη Υπηρεσιών",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_7 - Αγορές Παγίων",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "15.1 - Συμβόλαιο (Έξοδο)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "16.1 - Ενοίκια( Έξοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "16.1 - Ενοίκια( Έξοδο)",
    "Katigoria": "2_5 - Γενικά Έξοδα χωρίς δικαίωμα έκπτωσης Φ.Π.Α.",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "16.1 - Ενοίκια( Έξοδο)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "16.1 - Ενοίκια( Έξοδο)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "16.1 - Ενοίκια( Έξοδο)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "17.1 - Μισθοδοσία",
    "Katigoria": "2_6 - Αμοιβές και Παροχές προσωπικού",
    "XaraktirismosE3": "Ε3_581_001 - Παροχές σε εργαζόμενους/Μικτές αποδοχές",
    "Typos": 1
  },
  {
    "Name": "17.1 - Μισθοδοσία",
    "Katigoria": "2_6 - Αμοιβές και Παροχές προσωπικού",
    "XaraktirismosE3": "Ε3_581_002 - Παροχές σε εργαζόμενους/Εργοδοτικές εισφορές",
    "Typos": 1
  },
  {
    "Name": "17.1 - Μισθοδοσία",
    "Katigoria": "2_6 - Αμοιβές και Παροχές προσωπικού",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "17.1 - Μισθοδοσία",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "17.2 - Αποσβέσεις",
    "Katigoria": "2_8 - Αποσβέσεις Παγίων",
    "XaraktirismosE3": "Ε3_587 - Αποσβέσεις",
    "Typos": 1
  },
  {
    "Name": "17.2 - Αποσβέσεις",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_8 - Έσοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_9 - Έσοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_001 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές - Επιτηδευματιών",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_002 - Πωλήσεις αγαθών και υπηρεσιών Χονδρικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_003 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_004 - Πωλήσεις αγαθών και υπηρεσιών Λιανικές άρθρου 39α παρ 5 του Κώδικα Φ.Π.Α.",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_005 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_006 - Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_561_007 - Πωλήσεις αγαθών και υπηρεσιών Λοιπά",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_562 - Λοιπά συνήθη έσοδα",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_563 - Πιστωτικοί τόκοι και συναφή έσοδα",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_564 - Πιστωτικές συναλλαγματικές διαφορές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_565 - Έσοδα συμμετοχών",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_566 - Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_567 - Κέρδη από αναστροφή προβλέψεων και απομειώσεων",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_568 - Κέρδη από επιμέτρηση στην εύλογη αξία",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_570 - Ασυνήθη έσοδα και κέρδη",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_595 - Έξοδα σε ιδιοπαραγωγή",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_596 - Επιδοτήσεις - Επιχορηγήσεις",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_597 - Επιδοτήσεις - Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_880_001 - Πωλήσεις Παγίων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_880_002 - Πωλήσεις Παγίων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_880_003 - Πωλήσεις Παγίων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_880_004 - Πωλήσεις Παγίων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_881_001 - Πωλήσεις για λογ/σμο Τρίτων Χονδρικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_881_002 - Πωλήσεις για λογ/σμο Τρίτων Λιανικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_881_003 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Ε3_881_004 - Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες Χώρες",
    "Typos": 0
  },
  {
    "Name": "17.3 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Λογιστική Βάση)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "17.4 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Φορολογική Βάση)",
    "Katigoria": "1_10 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "17.4 - Λοιπές Εγγραφές Τακτοποίησης Εσόδων (Φορολογική Βάση)",
    "Katigoria": "1_95 - Λοιπά πληροφοριακά στοιχεία Εσόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 0
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_10 - Έξοδα προηγούμενων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην προηγούμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_11 - Έξοδα επομένων χρήσεων",
    "XaraktirismosE3": "Επιτρέπονται οι σχετικοί χαρακτηρισμοί ανα τιμή ΣΤ.9. Μεταφορά προς υπολογισμό στην επόμενη χρήση",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_102_001 - Αγορές εμπορευμάτων χρήσης / Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_102_002 - Αγορές εμπορευμάτων χρήσης / Λιανικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_102_003 - Αγορές εμπορευμάτων χρήσης / άρθρου 39α παρ.5 του Κώδικα Φ.Π.Α.",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_102_004 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_102_005 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_102_006 - Αγορές εμπορευμάτων χρήσης (καθαρό ποσό)Λοιπά",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_202_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_202_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_202_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_202_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_202_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_302_001 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_302_002 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_302_003 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_302_004 - Αγορές πρώτων υλών και υλικών χρήσης  (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_302_005 - Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_313_001 - Αγορές ζώων - φυτών (καθαρό ποσό)/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_313_002 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_313_003 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_313_004 - Αγορές ζώων - φυτών (καθαρό ποσό)/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_313_005 - Αγορές ζώων - φυτών (καθαρό ποσό)/Λοιπά",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_581_001 - Παροχές σε εργαζόμενους/Μικτές αποδοχές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_581_002 - Παροχές σε εργαζόμενους/Εργοδοτικές εισφορές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_581_003 - Παροχές σε εργαζόμενους/Λοιπές παροχές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_582 - Ζημιές επιμέτρησης περιουσιακών στοιχείων",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_583 - Χρεωστικές συναλλαγματικές διαφορές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_584 - Ζημιές από διάθεση-απόσυρση μη κυκλοφορούντων περιουσιακών στοιχείων",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_001 - Προμήθειες διαχείρισης ημεδαπής - αλλοδαπής (management fees)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_002 - Δαπάνες από συνδεδεμένες επιχειρήσεις",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_003 - Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_004 - Δαπάνες για ενημερωτικές ημερίδες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_005 - Έξοδα υποδοχής και φιλοξενίας",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_006 - Έξοδα ταξιδιών",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_007 - Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_008 - Έξοδα και προμήθειες παραγγελιοδόχου για λογαριασμό αγροτών",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_009 - Λοιπές Αμοιβές για υπηρεσίες ημεδαπής",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_010 - Λοιπές Αμοιβές για υπηρεσίες αλλοδαπής",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_011 - Ενέργεια",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_012 - Ύδρευση",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_013 - Τηλεπικοινωνίες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_014 - Ενοίκια",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_015 - Διαφήμιση και προβολή",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_585_016 - Λοιπά έξοδα",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_586 - Χρεωστικοί τόκοι και συναφή έξοδα",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_587 - Αποσβέσεις",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_588 - Ασυνήθη έξοδα, ζημιές και πρόστιμα",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_589 - Προβλέψεις (εκτός από προβλέψεις για το προσωπικό)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_882_001 - Αγορές ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_882_002 - Αγορές ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_882_003 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_882_004 - Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_883_001 - Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_883_002 - Αγορές μη ενσώματων παγίων χρήσης/Λιανικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_883_003 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_883_004 - Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_103 - Απομείωση εμπορευμάτων",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_203 - Απομείωση πρώτων υλών και υλικών",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_303 - Απομείωση πρώτων υλών και υλικών",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_208 - Απομείωση προϊόντων και παραγωγής σε εξέλιξη",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_308 - Απομείωση προϊόντων και παραγωγής σε εξέλιξη",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_314 - Απομείωση ζώων, φυτών, εμπορευμάτων",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_106 - Ιδιοπαραγωγή παγίων, Αυτοπαραδόσεις, Καταστροφές αποθεμάτων (μείον)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_205 - Ιδιοπαραγωγή παγίων, Αυτοπαραδόσεις, Καταστροφές αποθεμάτων (μείον)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_305 - Ιδιοπαραγωγή παγίων, Αυτοπαραδόσεις, Καταστροφές αποθεμάτων (μείον)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_210 - Ιδιοπαραγωγή παγίων, Αυτοπαραδόσεις, Καταστροφές αποθεμάτων (μείον)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_310 - Ιδιοπαραγωγή παγίων, Αυτοπαραδόσεις, Καταστροφές αποθεμάτων (μείον)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Ε3_318 - Ιδιοπαραγωγή παγίων, Αυτοπαραδόσεις, Καταστροφές αποθεμάτων (μείον)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_13 - Αποθέματα έναρξης",
    "XaraktirismosE3": "Ε3_101 - Εμπορεύματα έναρξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_13 - Αποθέματα έναρξης",
    "XaraktirismosE3": "Ε3_201 - Πρώτες ύλες και υλικά έναρξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_13 - Αποθέματα έναρξης",
    "XaraktirismosE3": "Ε3_207 - Προϊόντα και παραγωγή σε εξέλιξη έναρξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_13 - Αποθέματα έναρξης",
    "XaraktirismosE3": "Ε3_301 - Πρώτες ύλες και υλικά έναρξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_13 - Αποθέματα έναρξης",
    "XaraktirismosE3": "Ε3_307 - Προϊόντα και παραγωγή σε εξέλιξη έναρξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_13 - Αποθέματα έναρξης",
    "XaraktirismosE3": "Ε3_312 - Αποθέματα έναρξης (ζώων - φυτών)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_14 - Αποθέματα λήξης",
    "XaraktirismosE3": "Ε3_104 - Εμπορεύματα λήξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_14 - Αποθέματα λήξης",
    "XaraktirismosE3": "Ε3_204 - Αποθέματα λήξης πρώτων υλών και υλικών",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_14 - Αποθέματα λήξης",
    "XaraktirismosE3": "Ε3_209 - Προϊόντα και παραγωγή σε εξέλιξη λήξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_14 - Αποθέματα λήξης",
    "XaraktirismosE3": "Ε3_304 - Αποθέματα λήξης πρώτων υλών και υλικών",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_14 - Αποθέματα λήξης",
    "XaraktirismosE3": "Ε3_309 - Προϊόντα και παραγωγή σε εξέλιξη λήξης",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_14 - Αποθέματα λήξης",
    "XaraktirismosE3": "Ε3_315 - Αποθέματα τέλους (ζώων - φυτών)",
    "Typos": 1
  },
  {
    "Name": "17.5 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Λογιστική Βάση)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "17.6 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Φορολογική Βάση)",
    "Katigoria": "2_12 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  },
  {
    "Name": "17.6 - Λοιπές Εγγραφές Τακτοποίησης Εξόδων (Φορολογική Βάση)",
    "Katigoria": "2_95 - Λοιπά πληροφοριακά στοιχεία Εξόδων",
    "XaraktirismosE3": "Χωρίς χαρακτηρισμό Ε3 (δεν ενημερώνει Ε3)",
    "Typos": 1
  }
];

export default dataRaw;
