import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled } from '@mui/material/styles';
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export interface DigiNavBarProps {
    logoUrl: string;
}
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function DigiNavBar(props: DigiNavBarProps) {
    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Container maxWidth={false}>

                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <img src={props.logoUrl} alt="logo" style={{ maxWidth: "160px" }} />
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            <img src={props.logoUrl} alt="logo" style={{ maxWidth: "160px" }} />
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />        
        </React.Fragment>
    );
}
